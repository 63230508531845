<template>
  <div>
    <client-header-slot />

    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="(tab, index) in tabs"
        :key="index"
        :to="{ name: tab.name }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="currentTab(tab.subTabs)"
        v-b-tooltip.hover
        :title="G_CE_GRID_COUNTERS[0][tab.text.toLowerCase()]"
      >
        {{ tab.text }}
        <span
          class="ml-3"
          v-if="
            G_CE_GRID_COUNTERS.length > 0 &&
            G_CE_GRID_COUNTERS[0][tab.text.toLowerCase()] > 0
          "
        >
          <feather-icon
            icon
            :badge="G_CE_GRID_COUNTERS[0][tab.text.toLowerCase()]"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import ClientHeaderSlot from "@/views/ce-digital/sub-modules/connection/views/clients/components/headerSlot.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ClientHeaderSlot,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_CE_GRID_COUNTERS: "connectionStore/G_CE_GRID_COUNTERS",
    }),
    tabs() {
      return this.$route.meta.tabs;
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  async created() {},
  methods: {
    currentTab(subtabs) {
      return subtabs.some((obj) => obj.name === this.currentRoute);
    },
  },
};
</script>