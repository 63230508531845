<template>
  <div>
    <!-- ini header-slot -->
    <header-slot :clients-search="true">
      <!-- Title Search Global -->
      <template #actions>
        <b-row class="w-100 pb-2">
          <b-col>
            <b-button
              v-show="isModuleActualEqualTwo"
              v-if="!isOnlyLead"
              variant="success"
              @click="openModalSearch()"
            >
              <feather-icon
                icon="SearchIcon"
                size="15"
                class="mr-50 text-white"
              />Payments Search
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="currentUser.role_id !== 4" class="w-100">
          <b-col cols="12" class="pl-0">
            <b-input-group class="mr-1">
              <b-input-group-prepend>
                <b-button variant="outline-info"> GLOBAL SEARCH </b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchText"
                placeholder="Search by Name, Account or the last 4 digits of Phone number"
                @keyup.enter="search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="search">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <!-- end header-slot -->

    <!-- Modal Search Global -->
    <modal-global-search
      v-if="modalGlobalSearch"
      :search-text="searchText"
      :role-id="currentUser.role_id"
      :program-id="programId"
      @closeModal="closeGlobalSearch"
    />
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
import ModalSearchPayment from "@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue";
import ClientService from "@/views/commons/components/clients/services/clients.services";

export default {
  components: {
    ModalGlobalSearch: () =>
      import("@/views/commons/components/clients/modals/ModalGlobalSearch.vue"),
    ModalSearchPayment,
  },
  data() {
    return {
      searchText: "",
      modalGlobalSearch: false,
      clientsGlobal: [],
      openModalSearchPayment: false,
      isOnlyLead: false,
      moduleActual: null,
      permitStatus: null,
      programId: 2,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    await Promise.all([this.moduleId()]);
  },
  mounted() {
    this.getAccessModules();
  },
  methods: {
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
    search() {
      this.modalGlobalSearch = true;
    },
    closeGlobalSearch() {
      this.modalGlobalSearch = false;
    },
  },
};
</script>
